section.module-animated_counter {
  position: relative;
  min-height: 20vh;

  //.bg {
  //    z-index: -1;
  //    width: 100vw;
  //    margin-left: -$page-margin;
  //    overflow-x: hidden;
  //    @include media-breakpoint-down(lg) {
  //        margin-left: -90px;
  //    }
  //
  //    img {
  //        width: 100vw;
  //        @include media-breakpoint-down(md) {
  //            width: unset;
  //            @include IE10-11(width, inherit);
  //        }
  //    }
  //}

  .counter-row {

    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .counter {
      color: white;

      .number {
        position: relative;
        margin-bottom: 20px;
        //font-size: 3.5rem;
        font-size: 4rem;
        line-height: 66px;

        &:after {
          content: "";
          position: absolute;
          bottom: -10px;
          left: 50%;
          transform: translateX(-50%);
          //width: 50%;
          width: 75%;
          height: 1px;
          background: #53A796;
        }
      }

      .label {
        font-size: 1rem;
        line-height: 20px;
        width: 75%;
        margin: auto;
      }
    }
  }
}
