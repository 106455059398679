/** Import Node Packages
  * Prefix your imports with `~` to grab from node_modules/
  * Example, ~bootstrap/scss/bootstrap grabs the bootstrap.scss file from
  * /node_modules/bootstrap/scss/bootstrap
  * @see https://github.com/webpack-contrib/sass-loader#imports
 **/
@import 'bootstrap';
@import 'mixins/mixins';

@import "~swiper/swiper-bundle";
@import "common/swiper";
@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';

/** Font Awesome 84kb compressed **/
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import '~@fortawesome/fontawesome-pro/scss/brands';
@import '~@fortawesome/fontawesome-pro/scss/light';
@import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/solid';

/** Import theme styles **/
@import 'common/global';
@import 'common/typography';
@import 'common/header';
@import 'common/footer';
@import 'common/navbar';
@import 'common/reusable_content';

/** modules **/
@import 'blocks/blocks';
/** base wp module styling **/
@import 'wp-blocks/wp-blocks';

/** Default woocommerce styling **/
@import 'woocommerce/woocommerce';
