/*
* Button Styles (via ACF)
*/

button {
  &.banner {
    background: silver;
    color: black;
  }

  &.tile-list {
    background: lightblue;
    color: black;
  }

  &.secondary {
    background: lightgrey;
    color: black;
  }

  &.success {
    background: limegreen;
    color: white;
  }

  &.info {
    background: lightskyblue;
    color: white;
  }

  &.warning {
    background: orangered;
    color: white;
  }

  &.danger {
    background: red;
    color: white;
  }

  &.link {
    background: blueviolet;
    color: white;
  }
}
.btn {
  background: $brand-primary;
  border-radius: 31px;
  min-width: 110px;
  height: 40px;
  padding: 10px 27px;
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  color: white;
  &:active, &:hover {
    background: $brand-primary-hover;
    color: white;
  }
}
