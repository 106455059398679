.module-featured-products {
  .header {
    height: 200px;
    background: $brand-offwhite;
    padding: 50px 0;
    position: relative;
    .circle {
      position: absolute;
      left: 50%;
      top: -54px;
      transform: translateX(-50%);
      width: 108px;
      height: 108px;
      border-radius: 50%;
      background: $brand-offwhite;
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 28px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 10px 0 10px;
        border-color: $brand-primary transparent transparent transparent;
      }
    }
    h4 {
      color: $darker-gray;
      font-weight: 600;
      margin-bottom: 30px;
    }
    a {
      background: $brand-primary;
      border-radius: 31px;
      min-width: 110px;
      height: 40px;
      padding: 10px 27px;
      font-weight: bold;
      font-size: 13px;
      line-height: 20px;
      text-transform: uppercase;
      color: white;
      &:focus, &:hover {
        background: lighten($brand-primary, 10%);
      }
    }
  }
  .product-list {
    background: white;
    padding: 46px 0;
    .product-container {
      margin-bottom: 15px;
      .product-image {
        margin-bottom: 10px;
        transition: all .2s ease-in-out;
        &:hover {
          transform: scale(1.02);
        }
      }
      .product-title {
        font-size: 17px;
        line-height: 25px;
      }
      .product-shortdesc {
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
      }
      .product-link {
        margin-top: 25px;
        .btn {
          width: 160px;
          border: 1px solid $brand-primary;
          background: white;
          border-radius: 20px;
          font-weight: bold;
          font-size: 13px;
          line-height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          text-transform: uppercase;
          color: $brand-primary;
          margin: auto;
          transition: all .25s ease;
          &:hover, &:active {
            background: $brand-primary;
            color: white;
          }
        }
      }
    }
  }
}
