@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700&display=swap');

body {
  font-size: 15px;
}

h1, h2, h3, h4, h5, h6, h7 {
  color: $darker-gray
}

h1 {
  font-size: 45px;
  line-height: 50px;
  font-weight: 600;
}

h2 {
  font-weight: 300;
  font-size: 55px;
  line-height: 60px;
}

h3 {
  font-size: 45px;
  line-height: 50px;
}

h4 {
  font-size: 35px;
  line-height: 40px;
}

h5 {
  font-size: 25px;
  line-height: 30px;
}

h6 {
  font-size: 20px;
  line-height: 25px;
}

h7 {
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

a {
  transition: all .3s ease-in-out;
  &:focus, &:hover {
    text-decoration: none;
  }
}

.hero-text {
  font-style: normal;
  font-weight: bold;
  font-size: 95px;
  line-height: 100px;
}
