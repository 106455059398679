.wp-block-table {
    figcaption {
        margin-top: 1rem;
    }
    td, th {
        padding: 5px 5%;
        border: solid 1px;
    }
}

.wp-block-table.is-style-stripes {
    border-color: #f0f0f0;

    td, th {
        border-color: transparent;
    }
    tbody tr:nth-child(odd) {
        background-color: #f0f0f0;
    }
}