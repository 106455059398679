header.banner.stuck {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background: white;
  width: 100%;
}

ul.nav.navbar-nav {
  @include media-breakpoint-down(md) {
    margin-top: 20px;
  }

  li.nav-item {
    > a.nav-link {
      padding: 12px 15px;
      font-weight: bold;
      font-size: 16px;
      color: grey;
      margin: 23px 0;
      transition: all 0.3s ease;

      @include media-breakpoint-down(md) {
        margin: 0;
      }

      &:after {
        transition: all 0.3s ease;
        content: '';
        display: block;
        height: 2px;
        width: 30px;
        background: transparent;
        margin: 0 auto;
        position: relative;
        top: 5px;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &:hover {
        color: black;

        &:after {
          background: black;
        }
      }
    }

    &.menu-item-has-children {
      > ul.dropdown-menu {
        animation: showSubMenu .3s ease;
        animation-fill-mode: forwards;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        border: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: 0;
        padding: 0;

        .dropdown-item {
          font-size: 16px;
          color: black;
          transition: all 0.3s ease;

          &:last-child {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }

          &:first-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }

          &:hover {
            background: #efefef;
            font-weight: bold;
            transition: all 0.3s ease;
          }
        }
      }

      @keyframes showSubMenu {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      @include media-breakpoint-down(md) {
        > ul.dropdown-menu {
          display: block;
          padding: 0 0 0 10px;
          box-shadow: none;
        }
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          > a.nav-link {
            &:after {
              background: black;
            }
          }

          > ul.dropdown-menu {
            display: block;
          }
        }
      }
    }
  }
}
