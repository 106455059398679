body {
  background-color: white;
}

/**
 * Global Stylings
 */
.content-banner {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.content-container {
  margin-top: 60px;
  margin-bottom: 60px;
  font-size: 17px;
  line-height: 25px;
}
.post-container {
  margin-top: 60px;
  margin-bottom: 60px;
  font-size: 17px;
  line-height: 25px;
  article.post {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid $brand-offwhite;
  }
  .entry-meta {
    margin-bottom: 5px;
    time {
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
  .post-title {
    margin-bottom: 10px;
  }
  .author-pic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 25px;
  }
  .author {
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    color: $brand-primary;
    margin-bottom: 5px;
  }
  .company {
    font-size: 15px;
    line-height: 20px;
  }
  .header {
    margin-bottom: 25px;
    time {
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .entry-title {
      margin-top: 25px;
    }
  }
  h2 {
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .entry-content {
    .col-md-12 {
      padding: 0;
    }
  }
}
.page-header {
  margin-bottom: 25px;
}

/**
  This file contains a bunch of default wordpress styling
 */

body#tinymce {
  margin: 12px !important;
}

/**
 * Comments override
**/
.comment-list {
  @extend .list-unstyled;
}

.comment-list ol {
  list-style: none;
}

.comment-form input[type='text'],
.comment-form input[type='email'],
.comment-form input[type='url'],
.comment-form textarea {
  @extend .form-control;
}

.comment-form input[type='submit'] {
  @extend .btn;
  @extend .btn-secondary;
}

/**
 * Search form
 */
.search-form label {
  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/**
 * Contact form
 */
.wpcf7-form {
  p {
    label {
      input {
        width: 100%;
      }
      textarea {
        width: 100%;
      }
    }
  }
}

/**
 * Media alignment
 */
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}

.aligncenter {
  display: block;
  margin: ($spacer / 2) auto;
  height: auto;
}

.alignleft,
.alignright {
  margin-bottom: ($spacer / 2);
  height: auto;
}

@include media-breakpoint-up(sm) {
  .alignleft {
    float: left;
    margin-right: ($spacer / 2);
  }

  .alignright {
    float: right;
    margin-left: ($spacer / 2);
  }
}

/**
 * Captions
 */
.wp-caption {
  @extend .figure;
}

.wp-caption img {
  @extend .figure-img;
  @extend .img-fluid;
}

.wp-caption-text {
  @extend .figure-caption;
}

/** Text meant only for screen readers */
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}

.list-style-reset {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.wp-block-media-text__media img {
  height: auto;
}
