section.module-tilelist {
  margin-top: 80px;
  margin-bottom: 80px;
  a {
    -webkit-transition: .25s all;
    transition: .25s all;
  }

  .card {
    border-radius: 0;
    overflow: hidden;
    transition: all .5s;
    background: none;
    border: none;
    height: 467px;

    .card-image {
      width: 100%;
      img {
        transition: all .5s;
        object-fit: cover;
        max-height: 200px;
        z-index: -1;
        border-radius: 0;
        width: 100%;
      }
    }

    .card-block {
      border: 1px solid #ced4da;
      border-radius: 4px;
      margin: auto;
      width: 100%;
      height: 100%;
      text-align: center;
      background-color: rgba(0, 0, 0, .7);
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .card-content {
        border-radius: 0;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: white;
        color: $brand-primary;
        padding-left: 15px;
        padding-right: 15px;

        h4 {
          font-size: 40px;
          text-transform: uppercase;
          font-size: 25px;
          line-height: 27px;
          margin-top: -20px;
          background: #fff;
          position: relative;
          width: 80%;
          border-radius: 15px;
          padding: 0.25em;

          @media( max-width: breakpoint('md')){
            font-size: 30px;
          }
        }

        p {
          font-size: 16px;
          margin: 0;
        }

        .btn {
          font-size: 18px;
          padding: 12px 28px;
          line-height: 14px;
        }
      }

    }


    &:hover {
      img {
        transform: scale(1.1);
      }
      .card-block {
        background-color: rgba(0, 174, 239, .95);

        .card-content {
          .btn-banner {
            transform: translate3d(0, 0px, 0);
            opacity: 1;
            height: 52px;
            border: 2px solid #ffffff;
            padding: 15px 50px;
            font-size: 16px;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .card:focus, .card:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
  }
  .card-inverse .card-img-overlay {
    background-color: rgba(51, 51, 51, .85);
    border-color: rgba(51, 51, 51, .85);
  }
}
