section.module-tabbed_content {

    .nav-tabs {

        display: block;
        flex-wrap: nowrap;

        .nav-item {
            display: inline-block;
        }

        .nav-link {
            color: darkgrey;
            border: none;

            &.active {
                color: cornflowerblue;
            }
        }
    }

    .tab-menu-bar {
        position: absolute;
        top: 0;
        right: 0;
        line-height: 14px;
        padding: 14px 15px 14px calc(100% - 30px);
        z-index: 2;
        transition: all 0.25s ease-out;
        transform-origin: calc(100% - 22px) 48%;

        > i {
            line-height: 14px;
        }

        &.active {
            transform: rotate(180deg);
            color: deepskyblue;
        }
    }

    .tab-pane {
        padding: 30px;
        border-radius: 0 0 15px 15px;

        &.active {
            background: white;
        }
    }
}

@include media-breakpoint-down(sm) {
    section.module-tabbed_content {
        .nav-tabs {

            .nav-item {
                display: block;
            }

            .nav-link {
                position: absolute;
                width: 100%;
                background: whitesmoke;
                border: 1px solid #dee2e6;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;

                transition: all 0.33s ease-in-out;

                &.visible, &.active {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: auto;
                }
                &.active {
                    background: white;
                    z-index: 1;
                }
            }
        }

        .tab-menu {
            min-height: 43px;
        }
    }
}