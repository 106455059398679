.flex-direction-nav {
  position: absolute;
  display: flex;
  top: 30%;
  width: 100%;
  list-style: none;

  .flex-nav-prev,
  .flex-nav-next {
    a {
      display: block;
      color: transparent;
      width: 30px;
      height: 30px;
      background: #000;
      content: "";
      border-radius: 50%;

      &:before,
      &:after {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

    }
  }

  .flex-nav-prev {
    position: absolute;
    left: 0;

    a {
      &:before {
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        color: #fff;
        content: "\f060"
      }
    }
  }

  .flex-nav-next {
    position: absolute;
    right: 0;

    a {
      &:before {
        font-family: "Font Awesome 5 Pro";
        font-weight: 300;
        color: #fff;
        content: "\f061"
      }

    }
  }
}