.wp-block-pullquote {
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 50px;
  text-align: left;
  color: $brand-primary;
  blockquote {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
    font-weight: 300;
    font-size: 25px;
    line-height: 37px;
  }
}
