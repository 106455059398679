.archive.woocommerce {
  .content-banner {
    height: 270px;
    margin-bottom: 30px;
  }
  .woocommerce-products-header__title {
    font-size: 35px;
    line-height: 40px;
  }
  .term-description {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 40px;
    p {
      margin-bottom: 0;
    }
  }
  .product-sort {
    min-height: 65px;
    margin-bottom: 20px;
    background: $brand-primary;
    padding: 10px 13px 10px 33px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include media-breakpoint-down(sm) {
      padding: 15px;
    }
    .woocommerce-result-count {
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
      text-transform: uppercase;
      color: white;
      margin-bottom: 0;
    }
    .woocommerce-ordering {
      margin-left: auto;
      margin-bottom: 0;
      @include media-breakpoint-down(sm) {
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
        width: 100%;
        select {
          width: 100%;
        }
      }
      select {
        outline: 0;
        padding: 12px 19px;
        border-radius: 53px;
        border: 0;
        font-size: 15px;
        line-height: 20px;
        color: $brand-primary;
      }
    }
  }
  #load-more {
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    background: white;
    color: $brand-gray;
    border: 2px solid #D1DBE4;
    box-sizing: border-box;
    border-radius: 25px;
    width: 224px;
    height: 50px;
    margin-bottom: 60px;
    transition: all .25s ease;
    i {
      margin-right: 15px;
    }
    &:hover, &:focus {
      color: white;
      background: $brand-gray
    }
  }
}
