.module-cta {
  min-height: 540px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 23px 0;
  .top {
    padding: 40px 30px;
  }
  .heading {
    font-weight: 600;
    font-size: 35px;
    line-height: 40px;
    color: white;
    margin-bottom: 10px;
  }
  .subheading {
    font-weight: 300;
    font-size: 25px;
    line-height: 37px;
    color: white;
    margin-bottom: 5px;
  }
  .link {
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    a {
      font-weight: bold;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      text-transform: uppercase;
      color: white;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
