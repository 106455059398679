.module-featured-article {
  .article {
    min-height: 555px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .article-block {
      background: rgba(55,99,175, .7);
      padding: 25px 30px;
      max-width: 457px;
      width: 100%;
      height: 140px;
      overflow: hidden;
      color: white;
      .title {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 3px;
      }
      .heading {
        font-weight: 600;
        font-size: 35px;
        line-height: 40px;
        margin-bottom: 7px;
      }
      .link {
        a {
          color: $brand-orange;
          font-weight: bold;
          font-size: 13px;
          line-height: 20px;
          text-transform: uppercase;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &.left {
        position: absolute;
        right: 0;
        bottom: 123px;
      }
      &.right {
        position: absolute;
        left: 0;
        top: 94px;
      }
    }
  }
}
