$hero-heading-font-size: 62px;
$hero-subheading-font-size: 22px;
$hero-heading-colour: white;
$hero-subheading-colour: white;
$hero-arrow-colour: white;
$hero-dot-colour: white;
$hero-banner-height: 743px;

.module-hero-banner {
  .banner-container {
    position: relative;
    background: #555555;
    width: 100%;
    //set max height of hero banner
    max-height: $hero-banner-height;

    .banner-dots {
      position: absolute;
      bottom: 30px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1000;
      text-align: center;

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          display: inline-block;
          margin: 0 5px;

          button {
            height: 15px;
            width: 15px;
            color: transparent;
            background: transparent;
            border: 1px solid $hero-dot-colour;
            padding: 0;
            margin: 0;
            overflow: hidden;
            border-radius: 50%;
            transition: .3s ease-in-out;

            &:hover,
            &:focus,
            &:active {
              background: $hero-dot-colour;
            }

            &.slick-active button {
              background: $hero-dot-colour;
            }
          }
        }
      }
    }

    .banner-controls {
      .arrow-left,
      .arrow-right {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 48px;
        width: 48px;
        margin: auto;
        z-index: 1000;
        color: white;
        opacity: .5;

        &:hover {
          cursor: pointer;
          opacity: .9;
        }
      }

      .arrow-left {
        left: 15%;
      }

      .arrow-right {
        right: 15%;
      }

      @include media-breakpoint-down(lg) {
        .arrow-left {
          left: 15px;
        }

        .arrow-right {
          right: 15px;
        }
      }
    }

    #hero_banner {
      .banner-content {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 100%);
        }
      }

      img {
        z-index: 0;
        width: 100%;
        height: $hero-banner-height;
        object-fit: cover;
      }

      .banner-caption {
        z-index: 1;
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-left: 30px;
        padding-right: 30px;

        .label {
          background: $brand-orange;
          border-radius: 40px;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          color: $darker-gray;
          text-align: center;
          text-transform: uppercase;
          padding: 6px 22px;
          margin-bottom: 40px;
        }

        .heading {
          font-size: $hero-heading-font-size;
          color: $hero-heading-colour;
          font-weight: 700;
          line-height: 60px;
          margin-bottom: 25px;
          text-transform: uppercase;
        }

        .subheading {
          font-size: $hero-subheading-font-size;
          color: $hero-subheading-colour;
          line-height: 28px;
          margin-bottom: 40px;
          font-style: italic;
        }

        .button-container {
          a {
            background-color: $brand-orange;
            border: 2px solid $brand-orange;
            box-sizing: border-box;
            border-radius: 40px;
            color: white;
            padding: 25px 33px;
            font-weight: bold;
            font-size: 24px;
            line-height: 0;
            text-transform: uppercase;

            &:hover,
            &:focus {
              color: $gray;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
