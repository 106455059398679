.module-banner {
  display: block;
  position: relative;

  .banner-container {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    margin: 0 auto;

    .banner-text {
      h2 {
        text-align: center;
      }
    }

    .banner-cards {
      background: #ffffff;
    }
  }
}
