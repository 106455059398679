$quote-icon-font-size: 60px;

blockquote.wp-block-quote {
  position: relative;
  padding: 15px 30px;

  &:not(.is-large) {
    &:not(.is-style-large) {
      border-left: none;
      padding: 15px 30px;
    }
  }
  &:before,
  &:after {
    font-family: "Font Awesome 5 Pro";
    font-size: $quote-icon-font-size;
    z-index: 1;
    color: #f7f7f7;
    position: absolute;
  }
  &:before {
    content: "\f10d";
    top: -($quote-icon-font-size / 4);
    left: -($quote-icon-font-size / 4);
  }
  //&:after {
  //  content: "\f10e";
  //  bottom: -($quote-font-size / 4);
  //  right: -($quote-font-size / 4);
  //}
  cite,
  p {
    position: relative;
    z-index: 2;
  }

  p {
    font-family: $font-family-serif;
  }
  cite {
    margin-left: 15px;
    &::before {
      content: "- ";
    }
  }
}