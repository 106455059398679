.accordion {
  width: 100%;
  padding: 5px 10px;

  .accordion-header {
    display: flex;
    justify-content: space-between;
    background: $white;
    border: none;
    color: map-get($theme-colors, 'secondary');
    font-size: 1.8em;
    cursor: pointer;

    h5 {
      text-align: left;
    }

    a {
      display: block;
      color: map-get($theme-colors, 'secondary');
      text-decoration: none;
    }
  }
}

.accordion-toggle {
  &.collapsed {
    &::after {
      color: map-get($theme-colors, 'primary');
      font-family: 'Font Awesome 5 Pro';
      content: '\f067';
    }
  }

  &::after {
    color: map-get($theme-colors, 'primary');
    display: inline-block;
    font-family: 'Font Awesome 5 Pro';
    content: '\f068';
    transition: all 250ms;
  }
}
