$testimonial-arrow-colour: black;
$testimonial-dot-colour: black;

.testimonial-container {
  position: relative;
  padding: 30px 20px;

  h4 {
    font-weight: 600;
  }

  .testimonial-dots {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    text-align: center;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        display: inline-block;
        margin: 0 5px;

        button {
          height: 15px;
          width: 15px;
          color: transparent;
          background: transparent;
          border: 1px solid $testimonial-dot-colour;
          padding: 0;
          margin: 0;
          overflow: hidden;
          border-radius: 50%;
          transition: .3s ease-in-out;

          &:hover,
          &:focus,
          &:active {
            background: $testimonial-dot-colour;
          }
        }

        &.slick-active button {
          background: white;
        }
      }
    }
  }

  .testimonial-controls {
    .arrow-left,
    .arrow-right {
      position: absolute;
      top: 0;
      bottom: 0;
      height: 48px;
      width: 48px;
      margin: auto;
      z-index: 1000;
      opacity: .7;

      &:hover {
        cursor: pointer;
        opacity: .9;
      }
    }
    .arrow-right {
      right: 0;
    }
  }

  .testimonials {
    margin-top: 60px;
    img {
      margin: 0 auto 20px;
    }
  }

  .testimonial-content {
    .content {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 60px;
      max-width: 1006px;
      padding: 0 30px;
    }
    .rating {
      opacity: .7;
      margin-bottom: 9px;
    }
    .author {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
      opacity: .7;
    }
  }
}
