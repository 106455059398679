.single-product-container {
  margin-top: 40px;
  .summary.entry-summary {
    padding: 0 20px;
  }
  .product_title {
    color: $gray;
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 0;
  }
  .description {
    margin-bottom: 20px;
    p {
      margin-bottom: 10px;
    }
  }
  .tagline {
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 20px;
  }
  .highlight {
    font-weight: 600;
    font-size: 35px;
    line-height: 40px;
    color: $darker-gray;
  }
  .accordion {
    border-top: 1px solid $brand-offwhite;
    padding: 0;
    .accordion-header {
      padding: 20px 10px;
      h5 {
        a {
          font-weight: bold;
          font-size: 15px;
          line-height: 20px;
          text-transform: uppercase;
          color: #132230;
        }
      }
      .accordion-toggle {
        line-height: 0.5em;
        &.collapsed {
          &:after {
            content: "\f067";
          }
        }
        &:after {
          color: #A9A9A9;
          content: "\f00d";
          font-size: 17px;
        }
      }
    }
    .accordion-content {
      padding-left: 10px;
      padding-right: 10px;
      color: #A9A9A9;
    }
  }
  .flex-direction-nav {
    display: none;
  }
  form.cart {
    flex-direction: column;
    max-width: 310px;
    padding: 0;
    table.variations {
      label {
        //display: none;
        font-weight: 700;
        color: #6d6e71;
      }
      select {
        border: 1px solid $brand-offwhite;
        color: $body;
        box-sizing: border-box;
        padding: 8px 20px;
        width: 168px;
        height: 40px;
        min-width: initial !important;
        outline: 0;
        margin-right: 15px;
      }
    }
    .woocommerce-variation.single_variation {
      // Something to do here...remove if something breaks
      display: none !important;
    }
    .woocommerce-variation-add-to-cart {
      display: flex;
      flex-wrap: wrap;
      .points {
        display: none;
      }
    }
    .minus {
      border: 1px solid $brand-offwhite;
      color: $brand-offwhite;
      background: white;
      height: 40px;
      outline: 0;
      padding-left: 15px;
      padding-right: 15px;
    }
    .quantity {
      margin: 0 !important;
      float: none !important;
      .qty {
        border-top: 1px solid $brand-offwhite;
        border-bottom: 1px solid $brand-offwhite;
        border-left: 0;
        border-right: 0;
        height: 40px;
        width: 40px;
        text-align: right;
        outline: 0;
      }
    }
    .plus {
      border: 1px solid $brand-offwhite;
      color: $brand-offwhite;
      background: white;
      height: 40px;
      outline: 0;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  div {
    .product {
      .availability {
        display: none;
      }
      p.stock {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .afterpay-payment-info {
    font-weight: 400;
    font-size: 15px;
  }
}
