.woocommerce, .woocommerce-page {
  .col2-set {
    display: flex;
    .col-1 {
      flex-basis: 50%;
      width: 50%;
      max-width: 50%;
    }
    .col-2 {
      flex-basis: 50%;
      width: 50%;
      max-width: 50%;
    }
  }

  @include media-breakpoint-down(xs) {
    .col2-set {
      flex-direction: column;
      .col-1, .col-2 {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
