section.module-bigtilelist {
  margin-top: 80px;
  a {
    -webkit-transition: .25s all;
    transition: .25s all;
  }

  .card {
    border-radius: 0;
    overflow: hidden;
    transition: all .5s;
    background: none;
    border: none;
    max-height: 467px;

    .card-image {
      width: 100%;
      img {
        transition: all .5s;
        object-fit: cover;
        max-height: 100%;
        z-index: -1;
        border-radius: 0;
        width: 100%;
      }
    }

    .card-block {
      margin: auto;
      width: 100%;
      height: 100%;
      text-align: center;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .card-content {
        border-radius: 0;
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $brand-primary;
        margin-top: 52px;
      
        h4 {
          text-transform: uppercase;
          position: relative;
          width: 100%;
          border-radius: 15px;
          padding: 0.25em;
          font-size: 38px;
          color: #fff;
          font-weight: 700;
          line-height: 60px;
          text-transform: uppercase;
          background: rgba(55,99,175,.7);
          width: 100%;
          border-radius: 0;

          @media( max-width: breakpoint('md')){
            font-size: 30px;
          }
        }
        
        .btn {
          font-size: 18px;
          padding: 12px 28px;
          line-height: 14px;
        }
        
        .btn-alt {
          background-color: $brand-orange;
          &:hover {
            color: $gray;
          }
        }
      }

    }


    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
}