@import "layout";
@import "flex-slider";
@import "content_product";
@import "single_product";
@import "archive_product";

#add_payment_method table.cart img,
.woocommerce-cart table.cart img,
.woocommerce-checkout table.cart img {
  width: 150px;
}

/**
 * More Quick View Modal content stuff
 */
.post-type-archive-product, .tax-product_cat {
  .pp_woocommerce_quick_view {
    .pp_content {
      overflow: hidden;
      .woocommerce-product-gallery__trigger {
        display: none;
      }
      .product_title {
        color: $gray;
        font-size: 35px;
        line-height: 40px;
        margin-bottom: 0;
      }
      .tagline {
        font-weight: 600;
        font-size: 17px;
        line-height: 25px;
        margin-bottom: 20px;
      }
      .stock {
        font-size: 18px;
        font-weight: 600;
      }
      .highlight {
        display: none;
      }
    }
  }
}

.woocommerce {
  /**
  * Quick View
  */
  &.quick-view {
    div.product {
      form.cart {
        width: 100%;
        margin-bottom: 15px;
        flex-direction: column;
        .single_add_to_cart_button {
          max-width: 310px;
          width: 100%;
        }
        .woocommerce-variation {
          display: none !important;
        }
        .minus {
          border: 1px solid $brand-offwhite;
          color: $brand-offwhite;
          background: white;
          height: 40px;
          outline: 0;
          padding-left: 15px;
          padding-right: 15px;
        }
        .quantity {
          margin: 0 !important;
          float: none !important;
          .qty {
            border-top: 1px solid $brand-offwhite;
            border-bottom: 1px solid $brand-offwhite;
            border-left: 0;
            border-right: 0;
            height: 40px;
            width: 40px;
            text-align: right;
            outline: 0;
          }
        }
        .plus {
          border: 1px solid $brand-offwhite;
          color: $brand-offwhite;
          background: white;
          height: 40px;
          outline: 0;
          padding-left: 15px;
          padding-right: 15px;
        }
        select {
          border: 1px solid #f0f1f1;
          color: #212529;
          box-sizing: border-box;
          padding: 8px 20px;
          width: 168px;
          height: 40px;
          min-width: initial !important;
          outline: 0;
          margin-right: 15px;
        }
      }
      .quick-view-detail-button {
        max-width: 310px;
        width: 100%;
        background-color: $brand-orange;
        font-size: 13px;
        line-height: 20px;
        transition: all .3s ease;
        &:hover {
          opacity: .9;
          background-color: $brand-orange;
        }
      }
    }
  }

  table.cart td.actions .input-text {
    width: auto;
  }

  .woocommerce-form-login .woocommerce-form-login__rememberme {
    display: block;
    width: 100%;
  }

  div.product {
    div.images {
      .flex-control-thumbs {
        li {
          border: 1px solid #A9A9A9;
          margin-right: 30px;
          width: 92px;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
    form.cart {
      display: flex;
      flex-wrap: wrap;
      .variations {
        width: 168px;
      }
      .button {
        background: $brand-primary;
        border-radius: 31px;
        width: 100%;
        height: 40px;
        color: white;
        font-size: 13px;
        line-height: 20px;
        text-transform: uppercase;
        outline: 0;
        margin-top: 1em;
        &:hover, &:focus {
          background: $brand-primary-hover;
        }
      }
      .quantity {
        float: none;
        margin: 0;
        display: inline-block;
      }
    }
    p.price {
      font-weight: 600;
      font-size: 35px;
      line-height: 40px;
      color: $darker-gray;
      .wholesale_price_container {
        font-size: 18px;
      }
    }
  }

  ul.products {
    li.product {
      a {
        img {
          width: auto;
          height: auto;
          max-height: 170px;
          margin: auto;
        }
      }
    }
  }

  form {
    .form-row {
      input.input-text,
      textarea {
        padding: 5px 10px;
      }
    }

    .form-row-first,
    .form-row-last {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  fieldset {
    margin-top: 30px;
  }

  span.woocommerce-input-wrapper {
    display: block;
    width: 100%;
  }

  .woocommerce-breadcrumb {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $brand-primary;
    font-weight: bold;
    .delimiter {
      color: $darker-gray;
      font-weight: normal;
    }
    a {
      color: $darker-gray;
      font-weight: normal;
      @extend .woocommerce-breadcrumb;
      &:hover, &:active {
        text-decoration: underline;
      }
    }
  }
  section {
    &.related {
      margin-top: 30px;
      h2 {
        font-size: 35px;
        line-height: 40px;
        color: $darker-gray;
        font-weight: 600;
        text-align: center;
        margin-bottom: 30px;
      }
    }
  }
  form.checkout {
    h3 {
      font-size: 24px;
    }
  }
}

.woocommerce, .woocommerce-cart {
  a.button.alt, button.button.alt {
    background-color: $brand-primary;
    text-transform: uppercase;
    border-radius: 31px;
    &:hover {
      background-color: $brand-primary-hover;
    }
  }
}

.wcppec-checkout-buttons {
  width: 310px;
}
