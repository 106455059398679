/**
 * Header
 */
.announcement-bar {
  width: 100%;
  height: auto;
  min-height: 50px;
  background-color: $gray;
  padding: 15px;
  line-height: 20px;
}

header {
  background-color: $brand-primary;
  .navbar-brand {
    padding-top: 20px;
    padding-bottom: 14px;
    margin-right: 50px;
  }
  .woocommerce-product-search {
    margin-right: 25px;
    position: relative;
    button {
      background: transparent;
      border: none;
      position: absolute;
      top: 10px;
      left: 10px;
      color: white;
      outline: 0;
    }
    .keyword-search {
      max-width: 260px;
      width: 100%;
      padding: 10px 15px 10px 35px;
      background: #2C5190;
      border-radius: 60px;
      color: white;
      border: none;
      outline: 0;
    }
  }
  .header-icons {
    > a {
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  header {
    .nav-extras {
      position: absolute;
      right: 10px;
      top: 5px;
      .woocommerce-product-search {
        margin-right: 0;
      }
      .header-icons {
        > a {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
    .navbar-brand {
      margin-right: 0;
    }
    .navbar-expand-lg {
      > .container {
        justify-content: flex-start;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  header {
    .nav-extras {
      position: absolute;
      right: 10px;
      top: 115px;
    }
  }
}

@include media-breakpoint-down(xs) {
  header {
    .nav-extras {
      max-width: 320px;
      width: 100%;
    }
    .woocommerce-product-search {
      width: 80%;
      margin-right: 15px;
    }
  }
}

@media screen and (max-width: 380px) {
  header {
    .nav-extras {
      position: relative;
      top: 0;
      right: auto;
    }
    .woocommerce-product-search {
      width: auto;
      margin-right: 0;
    }
  }
}
