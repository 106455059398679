.bs-callout {
  margin: 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eeeeee;
}

.bs-callout h4 {
  margin-top: 0;
}

.bs-callout p:last-child {
  margin-bottom: 0;
}

.bs-callout code,
.bs-callout .highlight {
  background-color: #ffffff;
}

/* Themes for different contexts */
.bs-callout-danger {
  background-color: lighten(map-get($theme-colors, 'danger'), 80%);
  border-color: lighten(map-get($theme-colors, 'danger'), 7%);
}

.bs-callout-warning {
  background-color: lighten(map-get($theme-colors, 'warning'), 80%);
  border-color: lighten(map-get($theme-colors, 'warning'), 7%);
}

.bs-callout-info {
  background-color: lighten(map-get($theme-colors, 'info'), 80%);
  border-color: lighten(map-get($theme-colors, 'info'), 7%);
}

.bs-callout-success {
  background-color: lighten(map-get($theme-colors, 'success'), 80%);
  border-color: lighten(map-get($theme-colors, 'success'), 7%);
}
