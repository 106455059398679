.products {
  .product-container {
    margin-bottom: 15px;
    .product-image {
      margin-bottom: 10px;
      transition: all .2s ease-in-out;
      &:hover {
        transform: scale(1.02);
      }
    }
    .product-title {
      font-size: 17px;
      line-height: 25px;
      text-align: center;
    }
    .product-shortdesc {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
    }
    .product-link {
      margin-top: 25px;
      .btn {
        width: 160px;
        border: 1px solid $brand-primary;
        background: white;
        border-radius: 20px;
        font-weight: bold;
        font-size: 13px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-transform: uppercase;
        color: $brand-primary;
        margin: auto;
        transition: all .25s ease;
        &:hover, &:active {
          background: $brand-primary;
          color: white;
        }
      }
    }
  }
}
