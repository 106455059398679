footer {
  background-color: gray;
  .heading {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  a {
    color: white;
    &:focus, &:hover {
      color: white;
      text-decoration: underline;
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 15px;
    }
  }
  .widget-footer {
    padding: 74px 0;
    background-color: $gray;
  }
  .bottom-footer {
    min-height: 90px;
    padding: 23px 0;
    background-color: $dark-gray;
    .logo-image {
      max-height: 44px;
      margin-right: 35px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .social-icons {
    a {
      color: $gray;
      margin-right: 5px;
      background-color: white;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      &:hover {
        color: $brand-primary;
      }
    }
  }
  .subscribe-box {
    position: relative;
    max-width: 360px;
    input {
      padding: 12px 15px;
      border-radius: 50px;
      border: 0;
      outline: 0;
      font-size: 13px;
      line-height: 20px;
      max-width: 360px;
      width: 100%;
    }
    button {
      background: transparent;
      border: none;
      position: absolute;
      outline: 0;
      color: $brand-primary;
      top: 10px;
      right: 10px;
    }
  }
}
