.module-bannercta {
  min-height: 540px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 23px 0;
  .cta-text {
    left: 50%;
    top: 50%;
    transform: translateY(-50%);
    max-width: 500px;
    position: absolute;
  }
  .top {
    padding: 40px 30px;
  }
  .heading {
    font-weight: 700;
    font-size: 80px;
    line-height: 1em;
    //color: $brand-primary;
    color: white;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .subheading {
    font-weight: 300;
    font-size: 25px;
    line-height: 37px;
    color: white;
    margin-bottom: 5px;
  }
  .link {
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    a {
      background: $brand-primary;
      border-radius: 31px;
      min-width: 110px;
      height: 40px;
      padding: 10px 27px;
      font-weight: bold;
      font-size: 13px;
      line-height: 20px;
      text-transform: uppercase;
      color: white;
      text-decoration: none;
      &:focus, &:hover {
        background: lighten($brand-primary, 10%);
      }
      &.btn-alt {
        background: gray;
        &:focus, &:hover {
          background: #5b6fba;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .cta-text {
      position: relative;
      left: initial;
      top: initial;
      transform: none;
      .heading {
        font-size: 64px;
      }
    }
    .banner-img {
      margin-bottom: 15px;
    }
  }
}
