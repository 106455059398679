.module-instagram {
  background: white;
  .logo-bar {
    width: 100%;
    padding: 15px;
    a {
      color: white;
      margin-right: 5px;
      background-color: $brand-primary;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
  }
}
